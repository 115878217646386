@import "../../styles/variables";
@import "../../styles/mixins";

button, input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  font-family: inherit;
  font: inherit;
}

.btn, [class*="btn-"] {

  padding:$padding ;
  background: #7D98A1;
  background: $btnColor;
  /* border:2px solid rgba(155,155,155,1); */
  /* border:2px solid #444; */

  cursor: pointer;
  transition: all .3s ease;
  margin:1rem 0;

  // color: #fff;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  // font-size: 1.0rem;
  text-align: center;
  text-decoration: none;
  @include flex($display:inline-flex,$alignX:flex-start);

  appearance:none;
  line-height: 1;
  @include navLink;


  .flex--row & {
    margin: calc(var(--margin) / 2 );
  }
}

[class*="btn-circle"], .btn-close{
  padding:$padding/2;
}

.btn {
  min-width: 150px;
  &--cta {
    background: $color-secondary;

  }



  &--download {
    background: none !important;
    background: $gray !important;
    color: inherit !important;
    color: $color-primary !important;
    // border: 1px solid;
    padding:.75rem 1rem !important;
    margin: $margin-text !important;
    // margin-left: 0 !important;
    // margin-bottom: 0 !important;

    &:hover{
      color: $color-secondary !important;
    }
  }
}

.btn-color {

  &--red {
    background: var(--red);
    color: #fff;

  }
  &--green {
    background: var(--green);
    color: #fff;

  }
}

.btn--icon i, .btn--icon  i {
    margin-right: 5px;
}

.btn--outline {
  background: none;
}

.btn--small {
  padding:var(--btnSmallPadding);
  font-size: var(--fontMedium);
  width: auto;
}

.btn--mini {
  padding:var(--btnMiniPadding);
  font-size: var(--fontSmall);
  width: auto;
}


.btn:hover {
  transform: scale(1.02);
}

.btn.clicked {
  transform: scale(.95);
  transition: all .1s ease-in-out;
  box-shadow: inset 0 0 0px 0 rgba(30,30,30,0.5);
  box-shadow: inset 0 0 8px 0 rgba(30,30,30,0.1);
  background: var(--color3);
}

.btn.mouse-down {
  animation: btnShiver .6s infinite;
}


.btn + .btn {
  /* margin-top: 15px; */
}

@keyframes btnShiver {
  0%,100% {
    transform: rotate(0) scale(1);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: rotate(-2deg);
  }
  20%, 40%, 60%, 80% {
    transform: rotate(2deg);
  }
}
