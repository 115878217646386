
.cookies {
    background: #e3e3e3;
    padding: 1rem;
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all .3s ease;
    transform: translateY(100%);


    &--not-approved {
      transform: none;
    }

    @media screen and (min-width: 768px){
      bottom: 0;
      top:auto;
      transform: translateY(100%);


      &--not-approved {
        transform: none;
      }
    }

   &__content {
      font-size: .7rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1100px;
      padding: 0 3rem;
      margin: auto;

      p {
         flex:1;
         margin: auto 1rem auto 0;

         font-size: inherit;
         max-width: 80%;
      }

      .btn {
        flex:none;
        width: auto;
      }
   }
}
