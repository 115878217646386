// @import "../../styles/base";
@import "../../styles/variables";
@import "../../styles/mixins";

.footer {
  overflow: hidden;
  position: relative;
  background: #fff;
  background: $gray--light;

  .socials {
    right: 3rem;

    @include mqTo($portrait - 1){
      display: none;
    }

  }

  &__image {

    @include mqFrom($portrait){
      @include flex($alignX:flex-start);
      width: 50%;
      margin-left: auto;
      img {
        max-width: 75%;
        width: 100%;
        height: 55vh;
        object-fit: cover;
        min-height: 500px;
      }
    }
  }

  .logo--huge {
    @include absFill;
    z-index: 1;
    @include flex($alignY:flex-end);
    pointer-events: none;

    img, svg {

      position: relative;
      max-width: 90%;
      width: 100%;
      margin: $margin/2 $margin/2;

      path {
        fill:#fff !important;
      }


      @include mqFrom($portrait){
        max-width: 30%;

        path {
          fill:#000 !important;
        }
      }
    }
  }
}
