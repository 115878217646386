// @import "../../styles/base";
@import "../../styles/variables";
@import "../../styles/mixins";

.hero {
  position: relative;
  z-index: 1;
  z-index: 2;
  max-width: 100vw;
  overflow-x: hidden;
  @include mqTo($portrait - 1){
    @include flex($alignX:flex-end);
  }

  video, .hero__bg {
    width: 100%;
    // width: auto;
    // height: 66vh;
    max-width: none;
    margin: auto;

    @include mqFrom($portrait){
      width: 100%;
      height: auto;
    }
  }

  .hero__bg {
    // @include mqFrom($portrait){
    //   height: calc((1400 / 620) * 100%);
    //   height: calc((620 / 1400 ) * 100%);
    //   object-fit: cover;
    // }
  }

  &__logo {
    position: absolute;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    margin-bottom: $padding;
    max-width: 90%;
    width: 100%;

    @include mqFrom($portrait){
      max-width: 45vw;
      margin-bottom: $margin;
    }
  }
}
