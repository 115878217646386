@import "../../styles/base";
$columnsClassTotal: 6;

[class*="flex-cols"]{
  @include flex($dir:column);
  width: 100%;

  @include mqFrom($portrait){
    @include flex();
  }
}

.flex {
  @include flex();

  &--col {
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    align-items: flex-start;
  }

  &-reverse {
    flex-direction: column-reverse;

    @include mqFrom($portrait){
      flex-direction: row-reverse;
    }
  }

  &-cols {
    overflow: hidden;
    
    @include mqFrom($portrait){
      // @include flex();

      overflow: initial;

      &__col {
        flex:1;
        justify-content: flex-start;

        @include mqFrom($portrait){
          @for $i from 1 through $columnsClassTotal {
            &--#{$i} {
              width: 100% / $i;
            }
          }
        }
      }

      @include mqFrom($portrait){
        @for $i from 1 through $columnsClassTotal {
          &--#{$i} {
            .flex-cols__col {
              width: 100% / $i;
            }
          }
        }
      }
    }

  }
}


.wrapper {
  width: 100%;
  margin: auto;

  @include mqFrom($portrait){
    max-width: 90%;
  }
  @include mqFrom($desktop){
    max-width: 85%;
  }
  @include mqFrom($xl){
    max-width: 80%;
  }

  .inner {
    @include innerPadding($margin);

    .single-page & {
      &:not(.treatments-section){
        padding-top: 0;
      }
    }
  }

  &.connect-- {

    @include mqTo($portrait - 1){

      &up {
        .inner {
          padding-top: 0;
        }
      }
      &down {
        .inner {
          padding-bottom: 0;
        }
      }
    }
  }
}


.padd {
  // @include padding($margin);
  @include innerPadding($margin);

  @include mqFrom($portrait){
    max-width: calc(100%/2);
    align-self: flex-start;
    margin-right: auto;
  }

  @include mqFrom($desktop){
    margin-top: auto;
    margin-top: 9vh;
    margin-bottom: auto;
  }

  @include mqTo($portrait - 1){
    padding-bottom: 0;

    &:first-child:not(:only-child) {
      padding-top: 0;
    }


  }

}

.read-more {
  max-height: 300px;
  overflow: hidden;
  // background: inherit;
  position: relative;
  transition: all .6s $bezier;

  &:after, &__toggle {
    content: "";

    position: absolute;
    bottom:0;
    left: 0;
    right: 0;
    background: inherit;
    z-index: 0;
    height: 50px;
  }
  &:after {
    pointer-events: none;
  }
  &__toggle {
    @include navLink;
    position: absolute;

    content: "Läs mer";

    margin: auto;
    // width: 120px;
    right: auto;
    background: inherit;
    height: 40px;
    @include flex();
    z-index: 1;

  }
  &--open {
    max-height: 1000px;

    &:after, .read-more__toggle {
      display: none;
    }
  }
}

figure {
  @include mqTo($portrait - 1){
    overflow: hidden;
  }
}
