@import "../../styles/base";

.about-section {
  position: relative;
  img.about-section__image {
    @include mqTo($portrait - 1){
      margin-top: $margin/2;
      height: 60vh;
      object-fit: cover;
    }
  }

  img.parallax {
    max-width: 500px;

  }

  .flex-cols{
    position: relative;
    z-index: 1;
  }

  @include mqTo($portrait - 1){
    .btn {
      // width: 100%;
      margin-right: auto;

      &.max-on-mobile {
        @include negativeMargin(2.5);
        margin-bottom: -$negativeMargin*3;
        height: 100px;
        font-size: $font-size--medium;
        justify-content: center;
      }
    }
  }
}

.consultation {
  @include mqFrom($portrait){
    margin-top: $margin;
  }
}

.floater {
  max-width: 19%;
  position: absolute;
  z-index: 1;
  margin-left: -10%;
  top: 20%;

  @include mqTo($portrait - 1){
    display: none;
  }
}
