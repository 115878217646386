



@font-face {
  font-family: "Baskerville-Italic";
  src: url("Baskerville-Italic.woff2") format("woff2"),
    url("Baskerville-Italic.woff") format("woff"),
    url("Baskerville-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
