@import "../../../../styles/base";

.price-list {
  @include flex($dir:column);
  max-width: $max-width-text;

  &__item {
    @include flex($alignX:flex-start);
    @include flex($alignX:space-between);
    width: 100%;
    @include btnPadding;
    padding-left: 0 !important;
    border-top: 1px solid #fff;
    border-top: 1px solid $gray--dark;

    &:first-of-type {
      border-top: 0;
    }
    &__name {
      margin-right: $margin-text;
    }

    &__price {

    }
  }
}
