// @import "../../styles/base";
@import "../../styles/variables";
@import "../../styles/mixins";

.container {
  width: 100%;
  margin: auto;

  @include mqFrom($portrait){
    max-width: 90%;

  }

  .inner {
    @include padding($padding: $margin);
  }

  &.connect-- {

    @include mqTo($portrait - 1){

      &up {
        .inner {
          padding-top: 0;
        }
      }
      &down {
        .inner {
          padding-bottom: 0;
        }
      }
    }
  }
}
