@import "../../styles/variables";
@import "../../styles/mixins";

.checkbox {

  @include flex($alignX:flex-start);
  margin-top: $margin-text/2;

  label {
    flex:1;
  }

  .input {

  }

  input[type="checkbox"]{
    // @include btnPadding;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 0;
    border-radius: 0;
    width: 2.25rem;
    height: 2.25rem;
    margin: 0;
    margin-right: $margin-text;
    outline: none;

    &:checked {

      // background: red;

      + label {
        &:before, &:after {
          opacity: 1;
        }
      }

    }
  }

  label {
    font-size: $font-size--mini;
    line-height: 1.3;
    position: relative;
     @include flex($alignX:flex-start);

     &:before, &:after {
       content: "";
       width: $menuToggleLine;
       height: 1.75rem;
       background: #000;
      @include absFill;
      margin: auto;
      margin-top: .15rem;
      margin-left: -1.85rem;
      opacity: 0;
     }

     &:before {
       transform: rotate(-45deg);
     }
     &:after {
       transform: rotate(45deg);
     }
  }
}

.contact{
  // &__footer{
    @include contactArea;
    // padding: 0;
    // @include negativeMargin;

    @include flex($alignX:space-between);
    // padding: $padding*1.5 $padding;
    width: 100%;


    &__newsletter {
      @include flex($alignX:flex-start);
      width: 100%;
      margin-bottom: $margin;

      input[type="email"]{
        width: 100%;
      }

      span {
        width: 100%;
        margin-bottom: .3rem;
        display: block;
        font-size: $font-size--normal;

      }

    }

    &__info {
      font-size: $font-size--normal;
    }

    span:not(.contact__title) {
      margin-bottom: .4rem;
    }

    .contact__title {
      margin-bottom: $margin/2;
    }

    a {
      // border: 1px solid $gray--dark;
      // margin-bottom: $padding;
      margin-top: $padding/4;
      width: 120px;
      width: 155px;
      justify-content: flex-start;

      span {
        margin: auto 0 !important;
      }

      &:hover, &:focus, &:active {
        background: $pink;
        border-color: transparent;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    div {
      @include flex($dir:column, $align:flex-start);
    }



    .links {
      // justify-content: flex-end;
      // align-items: flex-end;
      @include mqFrom($portrait){
        flex:1;
        margin-left: $margin*2;
      }
    }
  // }
}

.contact-section {
  position: relative;

  [class*="title"]{
    order:0;
  }

  .flex-cols__col.padd {
    // max-width: 450px;

    @include mqTo($portrait - 1){
      padding-left: 0;
      padding-right: 0;
    }
  }

  img {

    @include mqTo($portrait - 1){
      height: 60vw;
      width: 140%;
      max-width: none;
      object-fit: cover;
      object-position: center;
      transform: translate(22%, 0rem);
      margin-left: -40%;
    }

    @include mqFrom($portrait){
      @include negativeInner($x:0);
      max-width: 150%;
      // max-width: 175%;
      // height: calc((1280 / 800)* 100%);
      min-height: 65vw;
      object-fit: cover;
      object-position: right;
      object-position: left;
      margin-left: -50% !important;
      // margin-left: -75% !important;
    }

    @include mqFrom($xl){
      @include negativeInner($x:0);
      min-height: 52vw;
      max-width: 150%;
      max-width: 175%;
      margin-left: -50% !important;
      margin-left: -75% !important;
    }

    @include mqFrom($portrait){
      // margin-left: -60% !important;
      max-width: 138%;

      min-height: 35vw;
      height: 650px;
      margin-left: -38% !important;
    }

    @include mqFrom($xxl){
      // height: 650px;

    }
  }
}


.socials {
  @include flex();
  padding: $margin 0 0;
  width: 100%;

  &__row {
    @include flex($alignX:space-between);
    width: 100%;
    align-self: stretch;
  }

  @include mqTo($portrait - 1 ){
    font-size: $font-size--mini;
  }

  @include mqFrom($portrait){
    @include absFill($left:auto, $top:auto);

    width: 0px;
    height: 0%;

    // flex:1;
    padding: 0;
    padding-bottom: 7%;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: auto;
    height: 175%;
    height: 175%;
    max-height: 140vh;
    max-height: 950px;
    width: 0;
    padding: 0;


    &__row {
      transform: rotate(-90deg) ;
      // margin-top: -125%;
      transform-origin: -150% 100%;
      transform-origin: 0% 0;
          // transform-origin: 170% 65%;
      // position: absolute;
      flex-wrap: nowrap;
      top:0;
      bottom:0;
      width: 0;
      width: 100%;
    margin: auto;
    height: 0;


      a {
        padding:  0 $margin/2;

      }


    }
  }
  @include mqFrom($desktop){
    right: -$margin*1;
  }
  @include mqFrom($xl){
    right: -$margin*4;
    right: -7vw;
  }

}
