@import "../../styles/variables";
@import "../../styles/mixins";

.content-section {
  position: relative;

  img, figure, p, ul, ol {
    margin-bottom: $padding;
    z-index: 0;
  }

  figure {
    img {
      width: 100%;
      margin: 0;
    }
  }



  img {
    margin-bottom: $margin;

    @include mqTo($portrait - 1){
      @include negativeMargin;

    }

  }

  ul {
    li {
      @include arrowBefore;
      margin-bottom: .3rem;
    }
  }
}
