
@import "../../styles/variables";
@import "../../styles/mixins";



.button-close {
 width:$menuToggleSize;
 height:$menuToggleSize;
-webkit-tap-highlight-color: transparent;
 position:relative;
 position:relative;
 cursor:pointer;
 // position: absolute;
 // right: $padding;
 // margin: auto;
 z-index: 20;
 // background:rgba(255,255,255,.4);
 border-radius:5px;

 transition:.25s ease-in-out;
position: relative;
 @include flex();

 &:before, &:after {
   content: "";
   width: $menuToggleLine;
   height: 80%;
   background: #000;
  @include absFill;
  margin: auto;
 }

 &:before {
   transform: rotate(-45deg);
 }
 &:after {
   transform: rotate(45deg);
 }


 span {
  display:block;
  background:#fff;
  background:#000;
  border-radius:2px;
  transition:.25s ease-in-out;

 }

 @include mqFrom($portrait){

   &:hover {
     background:rgba(255,255,255,.1);
   }
 }

 &__cross {
   position:absolute;
   height:100%;
   width:100%;
   transform:rotate(45deg);
   display: none;

   span{
     background: #000;
     &:nth-child(1){
       height:80%;
       width:$menuToggleLine;
       position:absolute;
       top:10%;
       left:48%;
       transition-delay:0s;
     }
     &:nth-child(2){
       width:80%;
       height:$menuToggleLine;
       position:absolute;
       left:10%;
       top:48%;
       transition-delay:.25s;
     }

   }
 }
}

.menu-toggle {

 .menu-toggle__cross {
   span {

   }

 }
}
