@import "reset";

@import "base";

*,*:before,*:after {
  box-sizing: border-box;
}

html, body {
  font-size: $font-size;

  // @include fontSize();
}

body {
  background: $pink--light;
  @include fontNormal;
  // min-height: 200vh;
  overflow-x: hidden;

  &.lock {
    @include mqTo($portrait - 1){
      overflow: hidden;
    }
  }
}

img, video, svg {
  vertical-align: bottom;
  max-width: 100%;
}

h1, h2, h3, h4, h5 {
  margin-bottom: 1.2rem;
}

[class*="title"]{
  margin-bottom: 1.1rem;
}

.break {
  display: block;
  height: $margin/2;
}

.hidden {
  display: none !important;

  &-mobile {
    @include mqTo($portrait - 1){
      display: none !important;
    }
  }
  &-desktop {
    @include mqFrom($portrait){
      display: none !important;
    }
  }
}

p {
  line-height: 1.4;
  max-width: $max-width-text;
  margin-bottom: 1.2rem;

  @include fontSize();


  @include mqFrom($landscape){
    // font-size: 1rem;
    line-height: 1.6;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

span {
  // @include fontNormal;

}

a {
  color: inherit;
  text-decoration: none;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"]{
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid $gray--light;
  @include btnPadding;
  font:inherit;
  @include mqTo($portrait - 1){
    font-size: 16px;
    line-height: 1;

    &::placeholder {
      line-height: 1.45;
    }
  }
}

.quote {

  &__author {
    display: block;
  }
}

.box {
  @include flex($alignX:flext-start);
  @include padding;
  @include innerPadding;

  @include mqTo($portrait - 1){
    padding-left: 0;
    padding-right: 0;
  }

  p {
    max-width: $max-width-text;
  }

  &:first-child {
    padding-top: 0;
    padding-bottom: 0;
  }

  &--center {
    @extend .box;
    @include flex();
    text-align: center;
  }
}

.box {

}

.main {
  transition: all .3s ease .15s;
  @include mqTo($portrait - 1){
    overflow: hidden;
    .menu-open & {
      filter: blur(10px);
      transition: all .6s ease 0s;
      transform:translateX(-10%);
    }
  }
}

.bg {
  &-yellow {
    background: $yellow;
    &--light {
      background: $yellow--light;
    }
  }
  &-blue {
    background: $blue;
    &--light {
      background: $blue--light;
    }
  }
  &-gray {
    background: $gray;
    &--light {
      background: $gray--light;
    }
  }
  &-white {
    background: $white;
  }
  &-pink {
    background: $pink;
    &--light {
      background: $pink--light;
    }
  }

  &-half {
    position: relative;
    z-index: 1;

    &:after {
      background: $gray--light;
      content: "";
      display: block;
      z-index: -1;
      pointer-events: none;
      @include absFill();
      // margin:auto (-5%) 0;
      @include negativeMargin;
      right: auto;
      left: auto;
      margin:auto;

      top:auto;
      height: 20%;
      width: 100vw;

    }
  }
}

body {
  &:after {
    content: "";
    @include absFill;
    background: url('../img/lathund.jpg') no-repeat;
    // background: url('../img/lathund2.jpg') no-repeat;
    background: url('../img/lathund3.jpg') no-repeat;
    background-size: contain;
    position: fixed;
    opacity: .5;
    z-index: 99999;
    pointer-events: none;
    display: none;
  }
}

.scroll-point {
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: hidden;

  &.flex-cols__col, &.flex-cols {
    overflow: visible;
  }

  h1,h2,h3,h4,h5,p,span,ul,ol, img, input, label, [class*="btn"] {
    transition: all .3s $bezier;
    opacity: 0;
    transform: translateY(1.5rem);

  }

  &.scroll-point--active {
    h1,h2,h3,h4,h5,p,span,ul,ol, img, input, label, [class*="btn"] {

      transition: opacity .3s $bezier .2s, transform .6s $bezier .1s;
      @include nthTransition;

      opacity: 1;
      transform: none;
    }
  }
}

.parallax {
  transition:  all .1s linear !important;
  will-change: transform;
  -webkit-backface-visibility: hidden;
transition:none !important;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -webkit-filter: opacity(.9999);
-webkit-font-smoothing: antialiased;
-webkit-font-smoothing: subpixel-antialiased;
-webkit-text-stroke: 0.35px;
  @include mqTo($portrait){
    transform:none !important;
  }
}
