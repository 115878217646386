@import "../../styles/base";

.intro-section {
  img {
    height: 100vw;
    // width: 65vw;
    min-width: 100%;
    object-fit: cover;
    object-position: left;

    @include mqFrom($portrait){
      // @include negativeInner($x:0);
      height: auto;
      width: auto;
      object-fit: contain;
      max-width: 115%;
      max-width: 125%;
      margin-left: -15% !important;
      margin-left: -25% !important;
      height: 75vh;
      object-fit: cover;
      width: 150%;
    }
  }

  figure {
    @include mqFrom($portrait){
      max-height: 50vh;
      overflow-y: hidden;
      overflow-x: visible;
      img {
        object-fit: cover;
      }
    }
  }
}
