
@import "../../styles/base";

// #behandlingar {
//
//   .single-page-open & {
//     .inner {
//       @include mqTo($portrait - 1){
//         padding-top: 9rem;
//       }
//     }
//   }
// }

.single-page-footer {
  @include mqTo($portrait - 1){
    // padding-top: 11rem !important;

    .inner {
      padding-bottom: 6rem !important;
    }
  }
}
