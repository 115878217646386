@import "../../styles/base";
.headline {
  width: 100%;
  @include innerPadding($y:0);

  .padd > & {
    padding: 0;
  }

  @include mqFrom($portrait){
    padding: 0;
  }
}
