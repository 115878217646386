
@import "../../styles/variables";
@import "../../styles/mixins";

.scroll-list {

  // padding: 0 0 0 $negativeMargin/2;
  // width: 100vw;
  // position: static;
  // left: 0;
  margin-left: 0;

  // @include negativeInner;

  + .scroll-list {

    // @include mqTo($portrait - 1){
    @include mqFrom($portrait){
      // margin-top: (-$padding);
      // margin-top: ($margin);

    }

  }

  @include mqFrom($desktop){
    margin: auto auto auto (-$negativeMargin*4);
    @include flex($alignX:flex-start);
  }

  &__list {
    @include flex($alignX:flex-start);
    overflow-y: visible;
    overflow-x: scroll;
    position: relative;

    @include mqFrom($desktop){
      // overflow: hidden;


    }

    > div {
      white-space: nowrap;
      @include flex($alignX:flex-start, $wrap:nowrap);
    }


  }

  &__item {
    @include navLink;
    // background: $blue;
    margin: 0 $padding $padding 0;
    margin:$padding/2;
    min-width: 50px;
    display: inline-block;
    display: flex;
    // flex:1;
    overflow: initial;

    &:first-child {
      margin-left: 0;

      @include mqFrom($desktop){
        margin-left: $padding/2;
      }
    }
  }

  &__message {
    padding: $padding/2 $negativeMargin*1.5;
    @include innerPadding($y:$padding);

    @include mqFrom($desktop){
      display: none;
    }
  }
}
