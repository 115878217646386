@mixin flex($display: flex, $dir: row, $align: center, $alignX: $align, $alignY: $align, $wrap:wrap) {
  display: $display;
  flex-wrap: $wrap;
  flex-direction: $dir;

  @if $dir == "row" {
    align-items: $alignY;
    justify-content: $alignX;
  } @else {
    justify-content: $alignY;
    align-items: $alignX;
  }
}

@mixin mqFrom($media: $landscape) {
  @media screen and (min-width: #{$media}) { @content; }
}
@mixin mqTo($media: $landscape) {
  @media screen and (max-width: #{$media}) { @content; }
}


@mixin absFill($top:0,$right:0,$bottom:0,$left:0) {
  position: absolute;
  top:$top;
  bottom:$bottom;
  right:$right;
  left:$left;
}

@mixin ul-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

//  @include aspect-ratio(16, 9);
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

@mixin maxWidth($width:$max-width){
  max-width: 100%;
  @include mqFrom($portrait){
    max-width: 90vw;
  }
  @include mqFrom($desktop){
    max-width: $max-width;
  }
  @include mqFrom($xl){
    max-width: $xl;
  }
}

@mixin fontSize($size:$font-size, $increase:0.05, $decrease:false){
  font-size: $size;

  @include mqFrom($portrait){
    font-size: $size + ($size * $increase);
  }
  @include mqFrom($desktop){
    font-size: $size + ($size * $increase * 1.5 );
  }
  @include mqFrom($xl){
    font-size: $size + ( $size * $increase * 2 );
  }

}

$decreaseDefault:.2;

$negativeMargin: ($padding * $decreaseDefault*6 );

@mixin negativeMargin($count:1) {
  margin-left:-($negativeMargin*$count);
  margin-right:-($negativeMargin*$count);
  width: calc(100% + #{$negativeMargin*$count*2});
  max-width: calc(100% + #{$negativeMargin*$count*2});
}

@mixin padding($padding:$padding, $paddingX:$padding, $paddingY:$padding, $decrease:$decreaseDefault){
  padding: ($paddingY * $decrease*4 ) ($paddingX * $decrease*4 );

  @include mqFrom($portrait){
    padding: ($paddingY * $decrease * 2) ($paddingX * $decrease * 2);
  }
  @include mqFrom($desktop){
    padding: $paddingY $paddingX;
    padding: ($paddingY * $decrease*6.5) ($paddingX * $decrease*6.5);
  }
  @include mqFrom($xl){
    padding: $paddingY $paddingX;
  }
}

@mixin innerPadding($x:$margin, $y:$margin){
  padding: ($y) ($x /2.5);

  @include mqFrom($portrait){
    padding: ($y ) ($x /2);
  }
  @include mqFrom($desktop){
    padding: ($y) ($x);
  }
  @include mqFrom($xl){
    padding: $y $x;
  }
}

@mixin negativeInner($x:(-$margin), $y:(-$margin)){
  // margin: ($y) ($x /2.5);

  @include mqFrom($portrait){
    margin: ($y ) ($x /2);
  }
  @include mqFrom($desktop){
    margin: ($y) ($x);
  }
  @include mqFrom($xl){
    margin: $y $x;
  }
}

@mixin paddingHeader($padding:$padding, $paddingX:$padding, $paddingY:$padding, $decrease:.2){
  padding: ($paddingY * $decrease*4.5 ) ($paddingX * $decrease );

  @include mqFrom($portrait){
    padding: ($paddingY * $decrease * 2) ($paddingX * $decrease * 2);
  }
  @include mqFrom($desktop){
    padding: $paddingY $paddingX;
    padding: ($paddingY * $decrease*3) ($paddingX * $decrease*3);
  }
  @include mqFrom($xl){
    // padding: $paddingY $paddingX;
  }
}



@mixin arrowBefore($size:1px) {
  $length:1.5rem;
  $arrowLength:$length/4;

  position: relative;
  @include flex($alignX:flex-start);
  padding-left: $length + $arrowLength*2;

  &:before, &:after {
    content: "";
    display: flex;
    position: absolute;
    top:0;
    bottom: 0;
    margin: auto;
  }

  &:after {
    transform: rotate(45deg);
    left: $length - $arrowLength*1.1;
    border-right:$size solid;
    border-top:$size solid;
    width: $arrowLength;
    height: $arrowLength;
  }
  &:before {
    content: "";
    height: $size;
    width: $length;
    background: $color-text;
    left: 0;

  }
}


@mixin btnPadding {
  padding: .65rem .35rem;
  padding: .65rem .55rem;
  padding: .75rem 1rem;


  @include mqFrom($portrait){
    padding: .65rem .85rem;
    padding: .85rem 1rem;
  }
}

@mixin navLink {
  transition: all .3s ease;
  position: relative;
  overflow: hidden;
  font-size: $font-size--btn;
  background: $gray;
  // color: #fff;

  @include btnPadding;

  .bg-pink &, .bg-pink--light & {
    background: $pink;
  }

  .bg-gray &, .bg-gray--light & {
    background: #fff;
    border-color: #fff;
  }

  &:after {
    content: "";
    display: none;
    height: $border-size;
    background: #fff;
    width: calc(100% - 2rem);
    margin-top: .5rem;
    position: absolute;
    bottom: 0;
    left: 1rem;
    max-width: 0;
    transition: all .3s ease 0s;

    @include mqFrom($portrait){
      // display: flex;
    }

  }

  &:hover, &--active, &.current {
    @include mqFrom($portrait){

      transform: translateY(-2px);
    }

    &:after {
      max-width: 100px;
      transition: all .6s ease .15s;

    }
  }
}

.nav-link {
  @include navLink;
}

@mixin contactArea {
  // @include flex($alignX:space-between);
  // padding: $padding*1.5 $padding;
  //
  // @include mqFrom($portrait){
  //   display: none;
  // }
  //
  // &__info {
  //   font-size: $font-size;
  // }
  //
  // span:not(.contact__title) {
  //   margin-bottom: .3rem;
  // }
  //
  // a {
  //   border: 1px solid $gray--dark;
  //
  //   &:hover, &:focus, &:active {
  //     background: $pink;
  //     border-color: transparent;
  //   }
  //
  //   &:last-child {
  //     margin-bottom: 0;
  //   }
  // }
  //
  // div {
  //   @include flex($dir:column, $align:flex-start);
  // }
}

@mixin nthTransition($animationDelay: 0.15s, $nthDelay: .05s, $elements: 20){

  @for $i from 0 to $elements+1 {
    // &:nth-child(#{$i}) {
    //   $newDelay: $nthDelay* $i + $animationDelay;
    //   transition: opacity .3s $bezier $newDelay*2, transform .6s $bezier $newDelay;
    // }
    //
    // @include mqFrom($portrait){
      &:nth-child(#{$i}) {
        $newDelay: $nthDelay* $i + $animationDelay;
        transition: opacity .6s $bezier $newDelay*1.5, transform .9s $bezier $newDelay;
      }
    // }
  }
}
