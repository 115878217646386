
//Breakpoints
$portrait:768px;
$landscape:1024px;
$desktop:1100px;
$xl:1350px;
$xxl:1500px;


// Colors general
$blue:#5c7eac;
$blue--light:lighten($blue,25%);
$blue--light:#adbed5;

$brown: #ac9e95;
$pink: #ddb3ae;
$pink--light: rgba($pink,.3);
$pink--light: #f0e4e2;
$pink--light: #e8d3cf;

$yellow: #eddd96;
$yellow--light: rgba($yellow,.3);

$black: #222222;
$gray: #f0ede8;
$gray--dark: darken($gray,25%);
$gray--light: lighten($gray,2%);
$gray--light: #f0ede8;
$gray--light: #f5f1ec;

$white: #f7f7f7;

// Color variables
$color-primary: $blue;
$color-secondary: $pink;
$color-bg: $white;
$color-bg--dark: $blue;


$color-bg--article: linear-gradient(125deg, $pink--light, $white);
$color-bg--article: $gray--light;
$color-text: $black;
$color-link: rgba(150,150,250,1);
$color-link--menu: #fff;



// Font styles
$font-size:15px;

// $fontTitle: "hq-sans-corp", sans-serif;





$line-height:1.5;
$line-height-heading:1.0;
$font-size--mini:.8rem;
$font-size--small:.95rem;
$font-size--small:.99rem;
$font-size--normal:1.075rem;
$font-size--btn:1.15rem;
$font-size--medium:1.7rem;
$font-size--large:4rem;


// Transitions
$bezier: cubic-bezier(.51,.14,.29,1);


// Distances
$gutter:3rem;
$padding: $gutter/3.5;
$margin: $gutter;
$margin-text: $gutter/4;


// Buttons
$btnToggleSize:2.7rem;

$btnColor:$blue;
$btnColorHover:rgba($btnColor,0.85);
$menuToggleSize:40px;
$menuToggleLine:1px;

// Max widths
$max-width:1100px;
$max-width-inner:900px;
$max-width-text:450px;
// $max-width-text:750px;
$max-width-header:65vw;
$max-width-article:750px;

// General Sizes
$border-size:2px;
$line-size:1px;
