@import "../../styles/base";


.certifications {
  @include mqFrom($portrait){
    // transform: translateY( -$margin*2);

    > .padd {
      margin-top: 0;
      padding-top: $margin-text;
    }
  }

  &__logo {
    width: 130px;
    margin-top: $margin-text;
  }
}
