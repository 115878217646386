@import "../../styles/variables";
@import "../../styles/mixins";
$navHeight:54px;
.page-wrapper {
  // @include flex();
  &.single-page-open {
    overflow: hidden;
  }
}

.main, .single-page {
  width: 100%;
  min-height: 100vh;
  transition: transform .9s $bezier .2s, clip-path .9s $bezier 0s, opacity .3s ease 0s, filter 1s ease;

  .single-page-open & {
    transition: transform .6s $bezier, clip-path .6s $bezier .3s, filter 1s ease;
  }

}

.main {
  .single-page-open & {
    transform:translate(-30%,0) scale(.8);

  }
}

.single-article {
  width: 100%;

  &__header {
    @include flex($alignX:flex-end);
    @include paddingHeader;
    background: #fff;
    height: $navHeight;
    padding-top:0;
    padding-bottom:0;

    @include mqFrom($portrait){
      max-width: none !important;
      .logo-link {
        position: relative;
      }
    }
  }

  .button-close {
    margin-right: .5rem;
    position: absolute;
  }
}

.single-page {
  @include flex($dir:column, $alignY:flex-start);

  position: fixed;
  top:0;
  bottom: 0;
  right: 0;
  transform:translate(100%,0);
  transform:translate(100vw,0);
  background: $color-bg--article;
  color: #f5f5f5;
  color: $color-text;
  clip-path: circle(60px at center);
  overflow: auto;
  z-index: 250;
  box-shadow: -10px 0 100px #333;
  // padding-bottom:150px;

  article {
    opacity: 0;
    width: 100%;
  }
  .single-page-open & {
    transform: none;
    clip-path: circle(100% at center);
    opacity: 1;

  }
}

article {


  [loading="true"] & {
    opacity: 0;
    transform: translateY(0px);
    transition: all .3s $bezier;
  }

  [loading="false"] & {
    transform: none;
    transition: all .6s $bezier .1s;

    .single-page-open & {
      opacity: 1;
    }
  }
}
