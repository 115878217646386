/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * ny.se
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2019
 */


/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 * You can use either the complete or subsetted fonts:
 * If you don’t require the entire range of characters, you can use the smaller, subsetted webfonts instead.
 * See "Glyphs & Languages" for an overview of the characters in the corresponding html file in the root directory of this package.
 *
 */

/* @font-face {
  font-family: "LL Brown Regular Web";
  src: url("lineto-brown-regular.eot");
  src: url("lineto-brown-regular.eot?#iefix") format("embedded-opentype"),
    url("lineto-brown-regular.woff2") format("woff2"),
    url("lineto-brown-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} */
@font-face {
  font-family: "LL Brown Light Web";
  src: url("BrownLLWeb-Light.woff2") format("woff2"),
    url("BrownLLWeb-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
