// @import "../../styles/base";
@import "../../styles/variables";
@import "../../styles/mixins";

$navHeight:54px;

.logo-link {
  margin: auto;
  position: relative;
  z-index: 20;
  .logo, svg {
    width: 70px;

    path {
      fill:#000 !important;
      transition: opacity .15s linear;
      .invert & {
        // fill:rgba(0,0,0,0) !important;
        opacity: 0;
      }
      .menu-open & {
        opacity: 1;
        fill:rgba(0,0,0,1) !important;
      }
    }
  }

  @include mqFrom($portrait){
  // @include mqFrom($desktop){
  // @include mqFrom($desktop){
    // display: none;
    position: absolute;
    margin: auto;
  }

  @media screen and (min-width: $portrait) and (max-width: 1420px){
    .menu-open & {
      position: relative;
    }
  }
}

.top-header {
  @include paddingHeader;
  @include flex($alignX:space-between);
  // background: $pink--light;
  position: sticky;
  top:0;
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;

  .menu-open & {
    background: $pink--light;
    background: $pink;
  }

  @include mqFrom($portrait){
    background: none !important;
    @include flex($alignX:center);
  }

  &.invert {
    a.booking {
      color: #fff;
    }
  }

  &__menu {
    @include flex($dir:column,$align:flex-start);
    background: $pink;
    background: url('http://placehold.it/900x1400')no-repeat;
    background: url('../../img/adela-menu.jpg')no-repeat;
    // background-size:cover;
    background-size:contain;
    background-position: center top;
    @include absFill;
    position: fixed;
    // transform: translateY(-100%) ;
    transform: translateX(100%) ;
    // transform: scale(.8);
    opacity:0;
    transition: all .45s $bezier;
    z-index: 10;
    margin-top: $navHeight - 1;

    padding: $padding*1.5 $padding;
    padding: $margin*.8 $padding*1.7;
    // padding: $margin;

    @include mqFrom($portrait){
      margin-top: auto;
      transform: none;
      opacity: 1;
      align-self: flex-end;
      margin-left: auto;
      padding: $padding;
      margin-right: 3rem;

      a:not(.booking) {
        opacity: 0;
      }

      a.booking {
        order: 10;
      }

      .menu-open & {
        a {
          opacity: 1;
        }
      }
    }

    a, .contact a {
      @include navLink;
      background: $pink--light;
      color: inherit;
      margin-bottom: $padding*1.8 ;
      // font-size: $font-size--normal;
      width: 150px;

      &:last-child{
        margin-bottom: 0;
      }

      &:hover, &:focus, &:active {
        background: $pink;
      }

      @include mqFrom($portrait){
        background: none;
        width: auto;
        margin-bottom: auto;

      }
    }

    &__footer {
      background: $pink--light;
      background: $pink;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: $padding*3 $padding*1.7;


      // @include contactArea;

      @include mqFrom($portrait){
        display: none !important;
      }

      &.contact a {
        background: none;
        border: 1px solid #555;
      }

    }

    .menu-open & {
      transform: none;
      transition: all .45s $bezier .1s;
      opacity: 1;

      a {
        @media screen and (min-width: $portrait) and (max-width: 1420px){
          // background: red;
          // padding-left: .35rem;
          // padding-right: .35rem;
        }

      }
    }

    @include mqFrom($portrait){
      @include flex();
      position: static;
      background: none;
      margin-top: auto;
    }
  }



  &__bg {
    // margin-top: 100vh;
    background: #fff;
    // position: sticky;
    position: fixed;
    left: 0;
    right: 0;
    top:0;
    height: $navHeight;
    z-index: 1;
    z-index: 2;
    pointer-events: none;
    // display: none;
    @include mqFrom($portrait){
      display: block;
      height: 65px;
      height: 78px;
      // background: $pink--light;

    }

  }
}
