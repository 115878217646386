
@import "../../styles/variables";
@import "../../styles/mixins";



.menu-toggle {
 width:$menuToggleSize;
 height:$menuToggleSize;
-webkit-tap-highlight-color: transparent;
 position:relative;
 position:relative;
 cursor:pointer;
 position: absolute;
 top:0;
 bottom:0;
 right: $padding;
 margin: auto;
 z-index: 20;
 // background:rgba(255,255,255,.4);
 border-radius:5px;

 transition:.25s ease-in-out;

  @include mqFrom($portrait){
    // display: none;
  }

 span {
  display:block;
  background:#fff;
  background:#000;
  border-radius:2px;
  transition:.25s ease-in-out;

  .invert & {
    // background: #fff;

    &:nth-child(1){
      transition-delay:.1s;
    }
    &:nth-child(2){
      transition-delay:.225s;
    }
    &:nth-child(3){
      transition-delay:.35s;
    }

  }

 }

 @include mqFrom($portrait){

   &:hover {
     background:rgba(255,255,255,.1);
   }
 }

 &__hamburger {
   position:absolute;
   height:100%;
   width:100%;
   // @include flex()
   span {
     width:60%;
     height:$menuToggleLine;
     position:relative;
     top:15%;
     left:20%;
     margin:15% 0;

     .invert & {
       background: #fff;
     }
     &:nth-child(1){
       transition-delay:.5s;
     }
     &:nth-child(2){
       transition-delay:.625s;
     }
     &:nth-child(3){
       transition-delay:.75s;
     }
   }
 }
 &__cross {
   position:absolute;
   height:100%;
   width:100%;
   transform:rotate(45deg);
   span{
     background: #000;
     &:nth-child(1){
       height:0%;
       width:$menuToggleLine;
       position:absolute;
       top:10%;
       left:48%;
       transition-delay:0s;
     }
     &:nth-child(2){
       width:0%;
       height:$menuToggleLine;
       position:absolute;
       left:10%;
       top:48%;
       transition-delay:.25s;
     }
   }
 }
}

.menu-toggle.open {
 .menu-toggle__hamburger {
   span {
     width:0%;
     &:nth-child(1){
       transition-delay:0s;
     }
     &:nth-child(2){
       transition-delay:.125s;
     }
     &:nth-child(3){
       transition-delay:.25s;
     }
   }
 }
 .menu-toggle__cross {
   span {
     &:nth-child(1){
       height:80%;
       transition-delay:.625s;
     }

     &:nth-child(2){
       width:80%;
       transition-delay:.375s;
     }
   }

 }
}
