@import "../fonts/brown";
@import "../fonts/baskerville";
@import "variables";
@import "mixins";

@mixin addFont($font:inherit, $size:inherit, $transform:initial, $weight:normal, $callback:sans-serif){
  font-weight: $weight;
  font-family:$font, $callback;
  // font-size: $size;
  text-transform: $transform;

}

@mixin fontSerif {
  @include addFont($weight:normal, $font:'Baskerville W01 Regular_705699',$callback:serif);
}

@mixin fontSerifItalic {
  @include addFont($weight:normal, $font:'Baskerville W01 Italic',$callback:serif);
  @include addFont($weight:normal, $font:'Baskerville-Italic',$callback:serif);
}

@mixin fontNormal {
  // @include addFont($weight:normal, $font:'Brown W01 Light');
  // @include addFont($weight:normal, $font:'LL Brown Regular Web');
  @include addFont($weight:normal, $font:'LL Brown Light Web');
}


@mixin fontTitle {
  // @include addFont($weight:normal, $font:'LL Brown Regular Web');
  @include addFont($weight:normal, $font:'LL Brown Light Web');

  // @include addFont($weight:normal, $font:'Brown W01 Regular');
}

.font {
  &--medium {
    font-size: $font-size--medium;

    &[class*="font-serif"] {
      font-size: $font-size--medium - .25rem;
      @include fontSize($font-size--medium );

    }
  }
}

.font-serif {
  @include fontSerif;
  &--italic {
    @include fontSerifItalic;
  }
}

[class*="title"]{
  @include fontTitle;
  line-height: 1.05;
}
[class*="serif"]{
  @include fontSerif;


}
[class*="serif--italic"]{
  @include fontSerifItalic;
}

h1 {
  @include fontTitle;
}

.title {
  font-size: $font-size--normal;

  @include fontSize($font-size--normal);


  &--medium {
    font-size: $font-size--medium;
    @include fontSize($font-size--medium);


  }

  &--normal {
    font-size: $font-size--normal;
    @include fontSize($font-size--normal);


  }
  &--small {
    font-size: $font-size--small;
    @include fontSize($font-size--small);

  }
}
